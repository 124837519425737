import localforage from "localforage";
import { Callback, Subscription } from "./Subscription";
import { LOADING } from "./Session";

export interface Setting {
  key: string;
  value: string | boolean | number | Array<string | boolean | number>;
  local?: boolean;
  obfuscate?: boolean;
  hidden?: boolean;
}

const order = ["model", "speaker", "show_dial"];
const getOrder = (k: string) => {
  return order.length - order.indexOf(k);
};

export class Settings {
  private store: LocalForage;
  private pubsub = new Subscription<Setting[]>();
  private settings: Setting[] | null | typeof LOADING = [];

  constructor(defaults: Setting[]) {
    this.store = localforage.createInstance({
      name: "settings",
    });

    this.preload(defaults).then((res) => this.getSettings());
  }

  preload = async (initial: Setting[]) => {
    await this.store.ready();
    if ((await this.store.keys()).length !== initial.length) {
      const current = await this.getSettings();

      await Promise.all(
        initial.map((i) => {
          const c = current.find((s) => s.key === i.key);

          return this.store.setItem(i.key, c ? c : i);
        })
      );
    }
  };

  getSettings = async () => {
    const keys = await this.store.keys();
    this.settings = (await Promise.all(
      keys.map((k) => this.store.getItem(k))
    )) as Setting[];

    this.settings.sort((a, b) => getOrder(b.key) - getOrder(a.key));
    this.pubsub.publish(this.settings);
    return this.settings;
  };

  getSetting = async (settingKey: string) => {
    const s = await this.getSettings();
    return s.find((s) => s.key === settingKey)?.value;
  };

  updateSetting = async (setting: Setting) => {
    await this.store.setItem(setting.key, {
      ...setting,
    });
    this.getSettings();
  };

  subscribe = (cb: Callback<Setting[] | null | typeof LOADING>) => {
    this.pubsub.subscribe(cb);
    cb(this.settings);
  };
}

const defaultSettings = [
  { key: "model", value: "styletts" },
  { key: "speaker", value: "dolly_nl" },
  { key: "show_dial", value: false },
  { key: "short_sentence_augment", value: false },
];

export const settingsAPI = new Settings(defaultSettings);
