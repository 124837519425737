import { InputBase } from "@mui/material";

import SayIcon from "@mui/icons-material/RecordVoiceOver";
import { ChangeEvent, useCallback } from "react";
import { AllErrors, Values } from "../../../hooks/useSimpleForm";
import { Chip } from "./ui/Chip";
import { RoundButton } from "./ui/RoundButton";

interface Props {
  setValues(values: Values | ((old: Values) => Values)): void;
  values: Values;
  errors?: AllErrors;
  valid: boolean;
  onButtonClick(ev: React.MouseEvent): void;
  onButtonLongPress(): void;
}

export const TextBox = (props: Props) => {
  const { setValues, values, errors, valid, onButtonClick, onButtonLongPress } =
    props;

  const handleTextChange = useCallback(
    (ev: ChangeEvent) =>
      setValues((v) => ({
        ...v,
        text_to_speak: (ev.target as HTMLInputElement).value,
      })),
    [setValues]
  );

  return (
    <div style={{ display: "flex", alignItems: "start", gap: 8 }}>
      <Chip style={{ display: "flex", flexGrow: 1 }}>
        <InputBase
          sx={{ ml: 1, flex: 1, pt: 1.5 }}
          name="text_to_speak"
          value={values["text_to_speak"]}
          onChange={handleTextChange}
          placeholder="Type je zinnen..."
          inputProps={{ "aria-label": "speak" }}
          multiline={true}
          maxRows={5}
          minRows={2}
          error={!!errors?.["text_to_speak"]}
        />
        {/* <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
    <SearchIcon />
  </IconButton> */}
      </Chip>
      <RoundButton
        color="primary"
        aria-label="Speak"
        type="button"
        disabled={!valid}
        size="large"
        onClick={onButtonClick}
        onLongPress={onButtonLongPress}
      >
        <SayIcon />
      </RoundButton>
    </div>
  );
};
