export const API_ROOT =
  process.env.REACT_APP_API_ROOT || window.location.origin; // "https://api.vocodo.ai";
export const VOCODA_ENDPOINT =
  process.env.REACT_APP_VOCODA_ENDPOINT || "/voices/0/sentences/fixture.mp3";
export const VOCODA_METHOD = process.env.REACT_APP_VOCODA_METHOD || "GET";
export const FLAG_ENDPOINT = process.env.REACT_APP_FLAG_ENDPOINT || "/flag";
export const SENTRY_FLAG_INFERENCE =
  process.env.REACT_APP_SENTRY_FLAG_INFERENCE || false;

console.info("DEBUG config", {
  VOCODA_ENDPOINT,
  VOCODA_METHOD,
  FLAG_ENDPOINT,
  SENTRY_FLAG_INFERENCE,
});
