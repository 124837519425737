import { LinearProgress, LinearProgressProps } from "@mui/material";
import { useEffect, useState } from "react";

export const LinearProgressDelayed = (
  props: LinearProgressProps & { delay?: number }
) => {
  const { delay = 500, ...rest } = props;
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    const t = setTimeout(() => {
      setShouldShow(true);
    }, delay);
    return () => clearTimeout(t);
  }, [delay]);

  return shouldShow ? <LinearProgress {...rest} /> : null;
};
