import styled from "@emotion/styled";
import { Sentiment } from "../../../../api/VocodaApi";

import { ReactComponent as Smile } from "../../../../assets/emoji/emoji_smile.svg";
import { ReactComponent as Smile2 } from "../../../../assets/emoji/emoji_smile2.svg";
import { ReactComponent as Neutral } from "../../../../assets/emoji/emoji_neutral.svg";
import { ReactComponent as Sad } from "../../../../assets/emoji/emoji_sad.svg";
import { ReactComponent as Sad2 } from "../../../../assets/emoji/emoji_sad2.svg";

const color = "#777788";

const Base = styled("div")(({ theme }) => ({
  width: 20,
  height: 20,
  borderRadius: 999,
  borderColor: color,
  borderWidth: 2,
  borderStyle: "solid",
  position: "absolute",
}));

const Eye = styled("div")<{
  left?: boolean;
  right?: boolean;
  smile: number;
  sad: boolean;
}>(({ theme, left, right, smile, sad }) => ({
  width: 4,
  height: smile > 0 ? 2 + smile : 4,
  borderRadius: smile > 0 ? "999px 999px 0px 0px" : 999,
  borderColor: color,
  background: color,
  borderWidth: smile > 0 ? 1 : 2,
  borderStyle: "solid",
  position: "absolute",
  top: smile > 0 ? 3 + smile : 4,
  left: left ? 3 : "auto",
  right: right ? 3 : "auto",
  transform: sad ? "rotate(180deg)" : "",
}));

const Mouth = styled("div")<{ smile: number; sad: boolean }>(
  ({ theme, smile, sad }) => ({
    width: 10,
    height: 2 * smile,
    borderRadius: smile > 0 ? "0px 0px 100% 100%" : 999,
    borderColor: color,
    background: color,

    borderWidth: 1,
    borderStyle: "solid",
    position: "absolute",
    bottom: 4 - smile,
    left: "50%",
    transform: `translateX(-50%) ${sad ? "rotate(180deg)" : ""}`,
  })
);

export interface Preset {
  name: string;
  sentiment: Sentiment;
  temperature: number;
}

interface Props {
  preset: Preset;
}

export const Emoji = (props: Props) => {
  const { preset } = props;

  switch (true) {
    case preset.temperature === 0 || preset.sentiment === "neutral":
      return (
        <Neutral
          style={{
            width: 20,
            height: 20,
            position: "absolute",
          }}
        />
      );

    case preset.sentiment === "positive" && preset.temperature > 1:
      return (
        <Smile2
          style={{
            width: 20,
            height: 20,
            position: "absolute",
          }}
        />
      );

    case preset.sentiment === "positive" && preset.temperature > 0:
      return (
        <Smile
          style={{
            width: 20,
            height: 20,
            position: "absolute",
          }}
        />
      );

    case preset.sentiment === "negative" && preset.temperature > 1:
      return (
        <Sad2
          style={{
            width: 20,
            height: 20,
            position: "absolute",
          }}
        />
      );

    case preset.sentiment === "negative" && preset.temperature > 0:
      return (
        <Sad
          style={{
            width: 20,
            height: 20,
            position: "absolute",
          }}
        />
      );
  }

  return (
    <Base>
      <Eye
        left
        smile={preset.temperature}
        sad={preset.sentiment === "negative"}
      />
      <Eye
        right
        smile={preset.temperature}
        sad={preset.sentiment === "negative"}
      />
      <Mouth smile={preset.temperature} sad={preset.sentiment === "negative"} />
    </Base>
  );
};
