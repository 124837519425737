import styled from "@emotion/styled";
import { useLongPress } from "../../hooks/useLongPress";
import { PropsWithChildren } from "react";
import { keyframes } from "@emotion/react";

interface Props
  extends PropsWithChildren,
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    > {
  color?: Colors;
  size?: Sizes;
  onLongPress?(): void;
}

const colorLookup = {
  primary: ["#3478F6", "#3478F6", "white", "#EFEFF6"],
  secondary: ["#EFEFF6", "#E8E8F2", "black", "white"],
  ghost: ["white", "#E8E8F2", "#919196", "#EFEFF6"],
} as const;
const sizeLookup = {
  large: 64,
  medium: 48,
} as const;

export type Colors = keyof typeof colorLookup;
export type Sizes = keyof typeof sizeLookup;

const anim = keyframes({
  "0%": {
    background: "white",
  },
  "100%": {
    background: colorLookup.primary[0],
  },
});

const RoundButtonInner = styled("button")<Partial<Props>>(
  ({ color = "primary", theme, size = "medium" }) => ({
    height: sizeLookup[size],
    width: sizeLookup[size],
    padding: "8px 24px 8px 24px",
    borderRadius: 999,
    borderWidth: 1,
    borderColor: colorLookup[color][1],
    borderStyle: "solid",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    background: colorLookup[color][0],
    color: colorLookup[color][2],
    "&:hover": {
      background: colorLookup[color][3],
      cursor: "pointer",
    },
    "&:active": {
      background: colorLookup[color][3],
      cursor: "pointer",
    },
    "&:focus": {
      background: colorLookup[color][3],
      cursor: "pointer",
    },
    "&:disabled": {
      background: "#E8E8F2",
      cursor: "pointer",
      borderWidth: 0,
    },
    "&[data-long-press]": {
      animation: `${anim} 0.75s ease-in 1 0.25s`,
    },
  })
);

export const RoundButton = (props: Props) => {
  const { onClick, onLongPress, ...rest } = props;

  return (
    <RoundButtonInner
      {...rest}
      onClick={!onLongPress ? onClick : undefined}
      ref={useLongPress(onLongPress, onClick as any)}
    />
  );
};
