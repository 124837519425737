import localforage from "localforage";

import * as Sentry from "@sentry/browser";

import {
  API_ROOT,
  FLAG_ENDPOINT,
  VOCODA_ENDPOINT,
  VOCODA_METHOD,
  SENTRY_FLAG_INFERENCE,
} from "./config";
import { settingsAPI } from "./Settings";
import { speechHistoryService } from "../views/Vocoda";

export const emotions = {
  positive: "Vrolijk",
  neutral: "Neutraal",
  negative: "Assertief",
} as const;

export type Sentiment = keyof typeof emotions;

export const emoji = {
  positive: ":)",
  neutral: "",
  negative: ":(",
} as Record<Sentiment, string>;

export interface Sentence {
  words: string;
  sentiment: Sentiment;
  temperature: number;
  created_at?: number;
  api_params?: any;
  repeat?: number;
  flagged?: boolean;
}

export class VocodaApi {
  private token;
  private store: LocalForage;

  constructor(token: string) {
    this.token = token;
    this.store = localforage.createInstance({
      name: "sentences",
    });
  }

  makeKey = (s: Sentence) => {
    return `${s.words}`;
    // return `${s.sentiment}__${s.temperature}__${s.words}`;
  };

  tryCache = (s: Sentence) => {
    return this.store.getItem<Blob>(this.makeKey(s));
  };

  setCache = (s: Sentence, b: Blob) => {
    return this.store.setItem(this.makeKey(s), b);
  };

  speak = async (s: Sentence, forceRequest?: boolean): Promise<string> => {
    const { token } = this;

    console.info("DEBUG speak", forceRequest);

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token ? `Basic ${token}` : undefined,
    };

    if (!headers?.["Authorization"]) {
      delete headers?.["Authorization"];
    }

    const speaker = await settingsAPI.getSetting("speaker");
    const model = await settingsAPI.getSetting("model");
    const augment = await settingsAPI.getSetting("short_sentence_augment");

    const params = {
      text: augment ? this.preProcessText(s.words) : s.words,
      model: model,
      speaker: speaker === "default" ? undefined : speaker,
      seed: Math.round(Math.random() * 999999),
    };

    await speechHistoryService.addSentence({
      ...s,
      api_params: params,
      flagged: false,
    });

    if (s.flagged && forceRequest) {
      await speechHistoryService.updateSentence({
        ...s,
        flagged: false,
      });
    }

    if (!forceRequest) {
      const c = await this.tryCache(s);

      if (c) {
        return this.blobToURI(c).catch((err) => {
          console.info("DEBUG error", err);
          return Promise.reject(err);
        });
      }
    }

    return fetch(`${API_ROOT}${VOCODA_ENDPOINT}`, {
      method: VOCODA_METHOD,
      headers: headers as HeadersInit,
      body: VOCODA_METHOD === "POST" ? JSON.stringify(params) : undefined,
    })
      .then((res) => res.json())
      .then((json) => {
        return fetch(json.audio_url);
      })
      .then((res) => res.blob())
      .then((b) => this.setCache(s, b))
      .then(this.blobToURI)
      .catch((err) => {
        console.info("DEBUG error", err);
        return Promise.reject(err);
      });
  };

  blobToURI = (b: Blob) => {
    const fr = new FileReader();
    return new Promise<string>((res, rej) => {
      fr.onload = () => {
        res(fr.result as string);
      };
      fr.onerror = rej;
      fr.readAsDataURL(b);
    });
  };

  preProcessText = (text: string) => {
    const clean = text.replaceAll(/[^a-zA-Z0-9.,!?-_\s']/g, "");
    return clean.length < 30 ? `. . . ! ${clean}!` : clean;
  };

  flag = async (sentence: Sentence) => {
    const { token } = this;

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token ? `Basic ${token}` : undefined,
    };

    if (!headers?.["Authorization"]) {
      delete headers?.["Authorization"];
    }

    speechHistoryService.updateSentence({ ...sentence, flagged: true });

    const c = await this.tryCache(sentence);
    let dataUri;
    if (c) {
      dataUri = await this.blobToURI(c);
    }

    if (SENTRY_FLAG_INFERENCE) {
      const ab = await c?.arrayBuffer();

      if (ab) {
        Sentry.withScope((scope) => {
          scope
            .setLevel("warning")
            .setTags({
              speaker: sentence.api_params.speaker,
              model: sentence.api_params.model,
            })
            .setExtras({
              ...sentence,
            })
            .addAttachment({
              filename: `${sentence.api_params.model}_${
                sentence.api_params.speaker
              }_${sentence.api_params.seed}_${sentence.words
                .replaceAll(/[^a-zA-Z0-9_-]/g, "_")
                .slice(0.8)}.wav`,
              data: new Uint8Array(ab),
              contentType: "audio/wav",
            });

          Sentry.captureMessage("INFERENCE_FLAGGED");
          scope.clearAttachments();
        });
      }
    }

    return fetch(`${API_ROOT}${FLAG_ENDPOINT}`, {
      method: "POST",
      headers: headers as HeadersInit,
      body: JSON.stringify({
        sentence,
        dataUri,
      }),
    }).then((res) => res.statusText);
  };
}
