import React from "react";
import { T } from "./T";
import { Chip, Colors } from "./Chip";
import { useLongPress } from "../../hooks/useLongPress";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

interface Props {
  onClick(ev: React.MouseEvent): void;
  onLongPress?(): void;
  text: string;
  color?: Colors;
  noWrap?: boolean;
}

export const anim = keyframes({
  "0%": {
    background: "white",
  },
  "100%": {
    background: "red",
  },
});

export const LongPressChip = styled(Chip)<Partial<Props>>(() => ({
  "&[data-long-press]": {
    animation: `${anim} 0.75s ease-in 1 0.25s`,
  },
}));

export const Shortcut = (props: Props) => {
  const { onClick, text, color, onLongPress, noWrap } = props;

  return (
    <LongPressChip
      color={color}
      onClick={!onLongPress ? onClick : undefined}
      ref={useLongPress(onLongPress, onClick as any)}
      noWrap={noWrap}
    >
      <T>
        {noWrap ? text.slice(0, 40) : text}
        {noWrap && text.length > 40 && "…"}
      </T>
    </LongPressChip>
  );
};
