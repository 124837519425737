import { Slide, Stack } from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Shortcut } from "./ui/Shortcut";
import { RoundButton } from "./ui/RoundButton";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Shortcut as SC, Shortcuts } from "../../../api/Shortcuts";

interface Props {
  onShortcutClick(words: string): void;
  onToggleClick(ev: React.MouseEvent): void;
  open: boolean;
}

const primaryShortcuts: SC[] = [
  { words: "Nee", color: "error" },
  { words: "Misschien", color: "warning" },
  // { words: "Okee", color: "info" },
  { words: "Ja", color: "success" },
];

const secondaryShortcuts: SC[] = [
  { words: "Hoi!", color: "info" },
  { words: "Sorry...", color: "secondary" },
  { words: "Ja! Graag!", color: "secondary" },
  { words: "Doei!", color: "primary" },
  // { words: "Goed", color: "success" },
  // { words: "Mooi", color: "success" },
  { words: "Dankjewel", color: "success" },
  // { words: "Wow!", color: "success" },
  // { words: "Later", color: "info" },
  // { words: "Soms", color: "info" },
  // { words: "Nooit", color: "info" },
  // { words: "Altijd", color: "info" },
  { words: "Help!", color: "error" },
];

export const shortcutsService = new Shortcuts([
  ...primaryShortcuts,
  ...secondaryShortcuts,
]);

export const ShortcutsPanel = (props: Props) => {
  const { onShortcutClick, open, onToggleClick } = props;

  const [shortcuts, setShortcuts] = useState<SC[]>([]);
  useEffect(() => {
    shortcutsService.subscribe(setShortcuts);
  }, []);

  const onShortcutDelete = useCallback((s: SC) => {
    shortcutsService.removeShortcut(s);
  }, []);

  const stackRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (stackRef) {
      stackRef.current?.scrollTo({ left: 0, top: 0, behavior: "smooth" });
    }
  }, [shortcuts]);

  return (
    <div style={{ position: "relative" }}>
      <Stack
        ref={stackRef}
        direction={"row"}
        spacing={1}
        justifyContent={"flex-start"}
        alignItems="center"
        flexWrap={open ? "wrap" : "nowrap"}
        sx={{
          overflowX: open ? "hidden" : "scroll",
          overflowY: !open ? "hidden" : "scroll",
          scrollSnapType: "both mandatory",
          scrollPadding: 4,
          pb: 1,
          pt: 1,
        }}
        maxHeight={open ? "50vh" : 80}
        rowGap={2}
      >
        <div
          style={{
            position: "absolute",
            right: 10,
            top: "auto",
            bottom: 40,
            transform: "translateY(50%)",
            transformOrigin: "50% 50%",
          }}
        >
          <RoundButton
            onClick={onToggleClick}
            size="medium"
            color="secondary"
            type="button"
          >
            {!open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </RoundButton>
        </div>
        {shortcuts.map((s, idx, arr) => {
          return (
            <div
              style={{
                scrollSnapAlign: "start",
                marginRight: idx === arr.length - 1 ? 80 : undefined,
              }}
              key={`${s.words}-${s.color}`}
            >
              <Slide
                in={true}
                enter={true}
                appear={true}
                timeout={350}
                direction="down"
              >
                <div>
                  <Shortcut
                    noWrap={!open}
                    color={s.color}
                    onClick={() => {
                      onShortcutClick(s.words);
                    }}
                    onLongPress={() => onShortcutDelete(s)}
                    text={s.words}
                  />
                </div>
              </Slide>
            </div>
          );
        })}
      </Stack>
    </div>
  );
};
