import { Box, Button, List, ListItem, Stack } from "@mui/material";
import { useSettings } from "../../components/SettingsProvider";
import { LOADING } from "../../api/Session";
import { EditableSetting } from "./EditableSetting";
import { useSession } from "../../components/SessionProvider";

interface Props {
  onClose(): void;
}

export const SettingsView = (props: Props) => {
  const { onClose } = props;

  const { settings, set } = useSettings();
  const { logout } = useSession();

  return (
    <Box
      sx={{
        p: 4,
        display: "flex",
        flexDirection: "column",
        background: "white",
        height: "100vh",
        margin: "auto",
      }}
      maxWidth={"sm"}
    >
      <List>
        {settings !== LOADING &&
          settings?.map((s) => {
            return (
              <ListItem key={s.key}>
                <EditableSetting setting={s} set={set} />
              </ListItem>
            );
          })}
      </List>

      <Stack direction={"row"} marginTop={4} justifyContent={"space-between"}>
        <Button onClick={logout} color="error">
          Logout
        </Button>
        <Button onClick={onClose}>Done</Button>
      </Stack>
    </Box>
  );
};
