import { Divider, MenuItem, Modal, Stack } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { Sentence } from "../../api/VocodaApi";

import { useVocoda } from "../../components/VocodaProvider";
import { RecentlyUsed } from "./components/RecentlyUsed";
import { SpeakForm } from "./components/SpeakForm";
import { shortcutsService } from "./components/ShortcutsPanel";
import { SpeechHistory } from "../../api/SpeechHistory";
import { LinearProgressDelayed } from "./components/ui/LinearProgressDelayed";
import { SettingsView } from "../Settings";
import { useSettings } from "../../components/SettingsProvider";
import { Setting } from "../../api/Settings";

interface Props {}

export const speechHistoryService = new SpeechHistory();

export const Vocoda = (props: Props) => {
  const { speak, flag } = useVocoda();

  const [recentlyUsed, setRecentlyUsed] = useState<Sentence[]>([]);

  useEffect(() => {
    speechHistoryService.subscribe(setRecentlyUsed);
  }, []);

  const [audioSrc, setAudioSrc] = useState<string>();
  const audioEl = useRef<HTMLAudioElement | null>();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!audioEl.current || !audioSrc) {
      return;
    }

    audioEl.current.autoplay = true;
    audioEl.current.src = audioSrc;

    audioEl.current.oncanplay = () => {
      if (!audioEl.current) {
        return;
      }

      audioEl.current.play().catch((e) => {
        console.error(e);
        setError(e);
        return Promise.reject(e);
      });
    };

    return () => {
      if (!audioEl.current) {
        return;
      }
      audioEl.current.autoplay = false;
      audioEl.current.pause();
      audioEl.current.oncanplay = null;
      audioEl.current.src = "";
    };
  }, [audioSrc, audioEl]);

  const speakSentence = useCallback(
    (sentence: Sentence, forceRequest?: boolean) => {
      if (!sentence.words) {
        return;
      }

      if (!audioEl.current) {
        audioEl.current = new Audio();
        audioEl.current.play();
      }

      if (!speak) {
        return;
      }

      setError(undefined);

      if (audioSrc) {
        setAudioSrc(undefined);
      }

      speak(sentence, forceRequest)
        .then((res) => {
          setAudioSrc(res);
        })
        .catch(setError)
        .finally(() => {
          setLoading(false);
        });
      setLoading(true);
    },
    [speak, setError, audioSrc, setAudioSrc]
  );

  const handleAddShortcut = useCallback((s: Sentence) => {
    shortcutsService.addShortcut({
      words: s.words,
      color:
        s.sentiment === "negative"
          ? "warning"
          : s.sentiment === "positive"
          ? "success"
          : "info",
    });
  }, []);

  const handleRemoveSentence = useCallback((sentence: Sentence) => {
    speechHistoryService.removeSentence(sentence);
  }, []);

  const handleFlagSentence = useCallback(
    (sentence: Sentence) => {
      if (!flag) {
        return;
      }

      if (!sentence.flagged) {
        flag(sentence);
      } else {
        speechHistoryService.updateSentence({ ...sentence, flagged: false });
      }
    },
    [flag]
  );

  const handleClearAll = useCallback(() => {
    speechHistoryService.clear();
  }, []);

  const [showSettings, setShowSettings] = useState(false);
  const handleShowSettingsClick = useCallback(() => {
    setShowSettings((s) => !s);
  }, []);

  const { settings, hasSettings, isLoading } = useSettings();

  const showDial =
    hasSettings &&
    !isLoading &&
    ((settings as Setting[])?.find((s) => s.key === "show_dial")
      ?.value as boolean);

  return (
    <Stack
      flexGrow={1}
      flexShrink={1}
      spacing={0}
      sx={{ overflowY: "hidden", position: "relative" }}
    >
      <MenuItem onClick={handleShowSettingsClick}>Instellingen</MenuItem>

      <Modal open={showSettings}>
        <SettingsView onClose={handleShowSettingsClick} />
      </Modal>

      <Stack
        direction="column-reverse"
        sx={{
          p: 1,
          pb: showDial ? 12 : 1,
          overflowY: "scroll",
          background: "linear-gradient(180deg, white -50%, #484B8E 500%)",
          overflowX: "hidden",
        }}
        spacing={4}
        flexGrow={1}
        flexShrink={1}
      >
        <RecentlyUsed
          sentences={recentlyUsed}
          speakSentence={speakSentence}
          onAddAsShortcut={handleAddShortcut}
          onRemoveSentence={handleRemoveSentence}
          onFlagSentence={handleFlagSentence}
          onClearAll={handleClearAll}
          error={error}
        />
      </Stack>

      <div style={{ position: "relative" }}>
        {loading && (
          <div
            style={{
              height: 16,
              position: "absolute",
              bottom: -12,
              left: 0,
              width: "100%",
            }}
          >
            <LinearProgressDelayed delay={300} variant="query" />
          </div>
        )}
      </div>
      <Divider />

      <SpeakForm speakSentence={speakSentence} showDial={showDial} />
    </Stack>
  );
};
