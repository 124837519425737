import styled from "@emotion/styled";
import { Sentiment } from "../../../../api/VocodaApi";
import { RoundButton } from "./RoundButton";
import { Emoji } from "./Emoji";

const BackgroundSliceA = styled("div")<{ hovered: boolean }>(
  ({ theme, hovered }) => ({
    boxSizing: "border-box",
    background: !hovered ? "white" : "#E8E8F2",
    borderColor: "#E8E8F2",
    borderWidth: 1,
    borderLeftWidth: 0.5,
    borderStyle: "solid",
    width: 171,
    height: 171,
    borderRadius: "171px 0px 0px 0px",
    position: "absolute",
    transformOrigin: "100% 100%",
    left: "50%",
    bottom: "100%",
    transform: "translate(-100%)",
    clipPath: `path('M 171 171 L 0 171 L${171 - Math.cos(Math.PI / 10) * 200} ${
      171 - Math.sin(Math.PI / 10) * 200
    }  Z')`,
  })
);

const BackgroundSliceB = styled("div")<{ hovered: boolean }>(
  ({ theme, hovered }) => ({
    boxSizing: "border-box",
    background: !hovered ? "white" : "#E8E8F2",
    borderColor: "#E8E8F2",
    borderWidth: 1,
    borderRightWidth: 0.5,
    borderStyle: "solid",
    width: 171,
    height: 171,
    borderRadius: "171px 0px 0px 0px",
    position: "absolute",
    transformOrigin: "100% 100%",
    left: "50%",
    bottom: "100%",
    transform: `translate(-100%) rotate(${(Math.PI / 10) * -3}rad)`,
    clipPath: `path('M 171 171 L 171 0 L  ${
      171 - Math.sin(Math.PI / 10) * 200
    } ${171 - Math.cos(Math.PI / 10) * 200} Z')`,
  })
);

const BackgroundSlice = (
  props: React.PropsWithChildren<{
    index: number;
    hovered: boolean;
  }>
) => {
  const { index, children, hovered } = props;

  return (
    <div
      style={{
        transform: `rotate(${(Math.PI / 5) * index}rad)`,
        position: "absolute",
        left: "50%",
        bottom: 0,
      }}
    >
      <BackgroundSliceA hovered={hovered} />
      <BackgroundSliceB hovered={hovered} />
      <div
        style={{
          position: "absolute",
          right: "100px",
          bottom: "10px",
          transform: `rotate(${(Math.PI / 5) * -index}rad)`,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export interface Preset {
  name: string;
  sentiment: Sentiment;
  temperature: number;
}

interface Props {
  preset: Preset;
  index: number;
  hovered: boolean;
}

export const EmotionSlice = (props: Props) => {
  const { preset, index, hovered } = props;

  return (
    <BackgroundSlice index={index} hovered={hovered}>
      <RoundButton
        color="ghost"
        type="button"
        style={{ pointerEvents: "none" }}
      >
        <Emoji preset={preset} />
      </RoundButton>
    </BackgroundSlice>
  );
};
