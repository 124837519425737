export type Callback<T = any> = (data: T) => void;

export class Subscription<T> {
  private subscribers: Array<Callback<T>> = [];

  subscribe(callback: Callback<T>) {
    if (!this.subscribers.includes(callback)) {
      this.subscribers.push(callback);
      return () => this.unsubscribe(callback);
    }
  }

  unsubscribe(callback: Callback<T>) {
    if (this.subscribers.includes(callback)) {
      this.subscribers.splice(this.subscribers.indexOf(callback), 1);
    }
  }

  publish(data: T) {
    this.subscribers.forEach((s) => s(data));
  }
}
