import "./App.css";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./styles/theme";

import {
  Button,
  Container,
  Slide,
  SlideProps,
  Snackbar,
  SnackbarContent,
  Stack,
} from "@mui/material";
import { Routes } from "./components/Routes";
import { SessionProvider } from "./components/SessionProvider";
import { SettingsProvider } from "./components/SettingsProvider";

import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { Subscription } from "./api/Subscription";
import { useCallback, useEffect, useState } from "react";

const appUpdate = new Subscription<ServiceWorkerRegistration>();

export const App = () => {
  const [update, setUpdate] = useState<ServiceWorkerRegistration>();

  const handleUpdate = useCallback(
    (swReg: ServiceWorkerRegistration) => {
      setUpdate(swReg);
    },
    [setUpdate]
  );

  useEffect(() => {
    appUpdate.subscribe(handleUpdate);
  }, [handleUpdate]);

  const handleForceUpdate = useCallback(() => {
    if (update?.waiting) {
      update.waiting.postMessage({ type: "SKIP_WAITING" });
    }
  }, [update]);

  return (
    <ThemeProvider theme={theme}>
      <SessionProvider>
        <SettingsProvider>
          <CssBaseline />

          <Snackbar
            open={!!update}
            TransitionComponent={(props: SlideProps) => (
              <Slide {...props} direction="down" />
            )}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <SnackbarContent
              message={"A new version is available"}
              action={
                <Button
                  onClick={handleForceUpdate}
                  size="small"
                  variant="contained"
                >
                  Update
                </Button>
              }
            />
          </Snackbar>
          <Container maxWidth="sm" sx={{ p: 0 }}>
            <Stack
              component="main"
              padding={2}
              sx={{
                height: "100dvh",
                maxHeight: "100dvh",
                overflowY: "hidden",
                margin: "auto",
              }}
            >
              <Routes />

              {/* <Copyright sx={{ mt: 2, flexShrink: 0, flexGrow: 0 }} /> */}
            </Stack>
          </Container>
        </SettingsProvider>
      </SessionProvider>
    </ThemeProvider>
  );
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerRegistration.register({
  onUpdate: (swReg) => {
    // console.info("DEBUG sw update");
    appUpdate.publish(swReg);
  },
  onSuccess: (swReg) => {
    // console.info("DEBUG sw success");
  },
  onActivate: (swReg) => {
    // console.info("DEBUG sw activate");
    window.location.reload();
  },
});
