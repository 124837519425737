import styled from "@emotion/styled";
import { Sentiment } from "../../../../api/VocodaApi";
import { RoundButton } from "./RoundButton";
import { Emoji } from "./Emoji";
import React from "react";

const BackgroundSemiCircle = styled("div")(({ theme }) => ({
  borderColor: "#E8E8F2",
  borderWidth: 1,
  borderStyle: "solid",
  width: 160,
  height: 80,
  borderRadius: "80px 80px 0px 0px",
  position: "absolute",
  bottom: "0%",
  left: "50%",
  transform: "translateX(-50%)",
  background: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  pointerEvents: "all",
}));

export interface Preset {
  name: string;
  sentiment: Sentiment;
  temperature: number;
}

interface Props {
  preset: Preset;
}

export const ActiveEmotion = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { preset } = props;
    return (
      <BackgroundSemiCircle ref={ref}>
        <RoundButton
          color="ghost"
          type="button"
          style={{ pointerEvents: "none" }}
        >
          <Emoji preset={preset} />
        </RoundButton>
      </BackgroundSemiCircle>
    );
  }
);
