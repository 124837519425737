import styled from "@emotion/styled";

interface Props {
  color?: keyof typeof colorLookup;
  noWrap?: boolean;
}

const colorLookup = {
  success: "green",
  primary: "yellow",
  secondary: "oker",
  warning: "orange",
  error: "red",
  info: "blue",
} as const;

export type Colors = keyof typeof colorLookup;

export const Chip = styled("div")<Partial<Props>>(
  ({ color, noWrap, theme }) => ({
    minHeight: 64,
    padding: "8px 24px 8px 24px",
    borderRadius: 24,
    borderWidth: 1,
    textWrap: noWrap ? "nowrap" : "pretty",
    userSelect: "none",
    borderColor: "#E8E8F2",
    borderStyle: "solid",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    background: `linear-gradient( 0deg, white -20%, rgba(255,255,255,0.8) 150% ), linear-gradient( 0deg, ${
      (color && colorLookup[color]) || "white"
    } 0%, ${(color && colorLookup[color]) || "white"} 20% )
      `,
    "&:hover": {
      background:
        (color &&
          `linear-gradient( 0deg, white -20%, rgba(255,255,255,0.8) 50% ), linear-gradient( 0deg, ${
            (color && colorLookup[color]) || "white"
          } 0%, ${(color && colorLookup[color]) || "white"} 20% )
      `) ||
        "#E8E8F2",
      cursor: "pointer",
    },
  })
);
