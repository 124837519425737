import { Box } from "@mui/material";
import { Values } from "../../../hooks/useSimpleForm";
import React, { useCallback, useEffect, useState } from "react";

import { ActiveEmotion, Preset } from "./ui/ActiveEmotion";
import { EmotionSlice } from "./ui/EmotionSlice";

interface Props {
  setValues(values: Values | ((old: Values) => Values)): void;
  values: Values;
}

const presets: Preset[] = [
  { name: "angry", sentiment: "negative", temperature: 2 },
  { name: "sad", sentiment: "negative", temperature: 1 },
  { name: "neutral", sentiment: "neutral", temperature: 0 },
  { name: "optimistic", sentiment: "positive", temperature: 1 },
  { name: "happy", sentiment: "positive", temperature: 2 },
];

export const EmotionPicker = (props: Props) => {
  const { setValues, values } = props;

  const [open, setOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [pieRef, setPieRef] = useState<HTMLDivElement | null>(null);

  const handleEmotionSelect = useCallback(
    (p: Preset) =>
      setValues((v) => ({
        ...v,
        temperature: p.temperature,
        sentiment: p.sentiment,
      })),
    [setValues]
  );

  useEffect(() => {
    if (pieRef) {
      const handleMouseDown = (ev: MouseEvent | TouchEvent) => {
        let index: number;
        ev.preventDefault();
        setOpen(true);
        setHoveredIndex(-1);

        // const mousePos = { x: ev.clientX, y: ev.clientY };
        const div = (ev.target as HTMLDivElement).getBoundingClientRect();
        const startPos = { x: div.x + div.width / 2, y: div.y + div.height };

        const handleMove = (ev: MouseEvent | TouchEvent) => {
          const pos = {
            x:
              ev.type === "mousemove"
                ? (ev as MouseEvent).clientX
                : (ev as TouchEvent).touches[0].clientX,
            y:
              ev.type === "mousemove"
                ? (ev as MouseEvent).clientY
                : (ev as TouchEvent).touches[0].clientY,
          };

          const d = { x: pos.x - startPos.x, y: pos.y - startPos.y };

          const dist = Math.sqrt(d.x * d.x + d.y * d.y);
          const angle = Math.atan2(d.y, d.x);

          const sector =
            dist > 80
              ? Math.max(
                  0,
                  Math.min(
                    (angle + Math.PI) / (Math.PI / presets.length),
                    presets.length - 1
                  )
                )
              : -1;
          index = Math.floor(sector);

          // console.info(
          //   "DEBUG angle",
          //   startPos,
          //   mousePos,
          //   angle,
          //   angle + Math.PI,
          //   sector,
          //   index
          // );
          setHoveredIndex(index);
        };

        const handleRelease = () => {
          setOpen(false);
          if (index > -1) {
            handleEmotionSelect(presets[index]);
          }
          window.removeEventListener("mouseup", handleRelease, true);
          window.removeEventListener("touchend", handleRelease, true);
          window.removeEventListener("mousemove", handleMove, true);
          window.removeEventListener("touchmove", handleMove, true);
        };
        window.addEventListener("mouseup", handleRelease, true);
        window.addEventListener("touchend", handleRelease, true);
        window.addEventListener("mousemove", handleMove, true);
        window.addEventListener("touchmove", handleMove, true);

        return false;
      };

      pieRef.addEventListener("mousedown", handleMouseDown);
      pieRef.addEventListener("touchstart", handleMouseDown);

      return () => {
        pieRef.removeEventListener("mousedown", handleMouseDown);
        pieRef.removeEventListener("touchstart", handleMouseDown);
      };
    }
  }, [pieRef, handleEmotionSelect]);

  return (
    <Box sx={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          position: "absolute",
          height: 200,
          bottom: 0,
          overflow: "hidden",
          pointerEvents: "none",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
          {open &&
            presets.map((p, idx) => {
              return (
                <EmotionSlice
                  key={p.name}
                  preset={p}
                  hovered={hoveredIndex === idx}
                  index={idx}
                />
              );
            })}
          <ActiveEmotion
            ref={setPieRef}
            preset={
              presets.find(
                (p) =>
                  p.sentiment === values.sentiment &&
                  p.temperature === values.temperature
              ) || presets[2]
            }
          />
        </div>
      </div>
    </Box>
  );
};
