import { ReactComponent as Logo } from "../../logo.svg";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
// import Checkbox from "@mui/material/Checkbox";
// import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
// import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useSession } from "../../components/SessionProvider";
import { releaseHash } from "../Vocoda/utils/appVersion";

interface Props {}

export const SignIn = (props: Props) => {
  const { loginBasic } = useSession();

  const handleSubmit = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);

      console.log({
        username: data.get("username"),
        password: data.get("password"),
        remember_me: data.get("remember_me"),
      });

      loginBasic({
        username: data.get("username") as string,
        password: data.get("password") as string,
        rememberMe: !!data.get("remember_me"),
      });
    },
    [loginBasic]
  );

  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Logo width={48} height={48} style={{ borderRadius: 12 }} />
      <Typography component="h1" variant="h5" color="GrayText">
        Sign in
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        {/* <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        /> */}
        <TextField
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="username"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        {/* <FormControlLabel
          control={
            <Checkbox name="remember_me" value="remember" color="primary" />
          }
          label="Remember me"
        /> */}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign In
        </Button>
        <Grid container>
          {/* <Grid item xs>
            <Link href="#" variant="body2">
              Forgot password?
            </Link>
          </Grid> */}
          <Grid>
            <div
              style={{
                padding: 8,
                background: releaseHash.color,
                color: releaseHash.isDark ? "#e8e8e8" : "#282828",
                borderRadius: 999,
              }}
            >
              {releaseHash.short}
            </div>
          </Grid>
          {/* <Grid item>
            <Link href="#" variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid> */}
        </Grid>
      </Box>
    </Box>
  );
};
