import { Callback, Subscription } from "./Subscription";
import { API_ROOT } from "./config";

export interface EmailCredentials {
  email: string;
  password: string;
  rememberMe?: boolean;
}

export interface BasicCredentials {
  username: string;
  password: string;
  rememberMe?: boolean;
}

interface TokenCredentials {
  token: string;
}

export interface Session {
  email: string;
  token: string;
  user_id: string;
  preferences: { [key: string]: string };
}

export const LOADING = "LOADING";

export class SessionApi {
  private session: Session | typeof LOADING | null = null;
  private pubsub = new Subscription<Session | typeof LOADING | null>();

  constructor() {
    // const oldSession = localStorage.getItem("session");
    // if (oldSession) {
    //   this.loginToken({ token: oldSession });
    // }

    this.loginRestore();
  }

  loginBasic = async ({ username, password, rememberMe }: BasicCredentials) => {
    this.session = LOADING;
    this.pubsub.publish(this.session);

    // this.session = await Promise.resolve({
    //   token: btoa(`${username}:${password}`),
    //   email: "string",
    //   user_id: "string",
    //   preferences: { voice: "test1" },
    // });

    this.session = await fetch(`${API_ROOT}/session`, {
      method: "POST",
      body: JSON.stringify({ username, password }),
    })
      .then((res) => {
        if (res.status >= 400) {
          return Promise.reject("login failed");
        }
        return Promise.resolve({
          token: "NOT_USED",
          email: "string",
          user_id: "string",
          preferences: { voice: "test1" },
        });
      })
      .catch((e) => null);

    if (!this.session) {
      return this.clear();
    }

    this.pubsub.publish(this.session);

    if (rememberMe) {
      localStorage.setItem("session", (this.session as Session).token);
    }
  };

  loginRestore = async () => {
    this.session = LOADING;
    this.pubsub.publish(this.session);

    // this.session = await Promise.resolve({
    //   token: btoa(`${username}:${password}`),
    //   email: "string",
    //   user_id: "string",
    //   preferences: { voice: "test1" },
    // });

    this.session = await fetch(`${API_ROOT}/session`, {
      method: "GET",
    })
      .then((res) => {
        if (res.status >= 400) {
          return Promise.reject("login failed");
        }
        return Promise.resolve({
          token: "NOT_USED",
          email: "string",
          user_id: "string",
          preferences: { voice: "test1" },
        });
      })
      .catch((e) => null);

    if (!this.session) {
      return this.logout();
    }

    this.pubsub.publish(this.session);
  };

  loginEmail = async ({ email, password, rememberMe }: EmailCredentials) => {
    this.session = LOADING;
    this.pubsub.publish(this.session);

    this.session = await Promise.resolve({
      email: "string",
      token: "string",
      user_id: "string",
      preferences: { voice: "test1" },
    });

    this.pubsub.publish(this.session);

    if (rememberMe) {
      localStorage.setItem("session", this.session.token);
    }
  };

  loginToken = async ({ token }: TokenCredentials) => {
    this.session = LOADING;
    this.pubsub.publish(this.session);

    this.session = await Promise.resolve({
      email: "string",
      token: token,
      user_id: "string",
      preferences: { voice: "test1" },
    });
    this.pubsub.publish(this.session);
  };

  logout = async () => {
    await fetch(`${API_ROOT}/session`, {
      method: "DELETE",
    })
      .then((res) => {
        if (res.status >= 400) {
          return Promise.reject("login failed");
        }
      })
      .catch((e) => null);

    this.clear();
  };

  clear = () => {
    this.session = null;
    localStorage.removeItem("session");
    this.pubsub.publish(this.session);
  };

  getSession() {
    return this.session;
  }

  subscribe = (cb: Callback<Session | typeof LOADING | null>) => {
    this.pubsub.subscribe(cb);
    cb(this.session);
  };
}
