import localforage from "localforage";
import { Callback, Subscription } from "./Subscription";

export interface Shortcut {
  words: string;
  color: "primary" | "secondary" | "info" | "success" | "error" | "warning";
  created_at?: number;
}

export class Shortcuts {
  private store: LocalForage;
  private pubsub = new Subscription<Shortcut[]>();
  private shortcuts: Shortcut[] = [];

  constructor(defaults: Shortcut[]) {
    this.store = localforage.createInstance({
      name: "shortcuts",
    });

    this.preload(defaults).then((res) => this.getShortcuts());
  }

  preload = async (shortcuts: Shortcut[]) => {
    await this.store.ready();
    if ((await this.store.keys()).length === 0) {
      await Promise.all(shortcuts.map((s) => this.store.setItem(s.words, s)));
    }
  };

  getShortcuts = async () => {
    const keys = await this.store.keys();
    this.shortcuts = (await Promise.all(
      keys.map((k) => this.store.getItem(k))
    )) as Shortcut[];

    this.shortcuts.sort((a, b) => (b.created_at || 0) - (a.created_at || 0));
    this.pubsub.publish(this.shortcuts);
    return this.shortcuts;
  };

  addShortcut = async (shortcut: Shortcut) => {
    await this.store.setItem(shortcut.words, {
      ...shortcut,
      created_at: Date.now(),
    });
    this.getShortcuts();
  };

  removeShortcut = async (shortcut: Shortcut) => {
    await this.store.removeItem(shortcut.words);
    this.getShortcuts();
  };

  subscribe = (cb: Callback<Shortcut[]>) => {
    this.pubsub.subscribe(cb);
    cb(this.shortcuts);
  };
}
