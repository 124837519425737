import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { LOADING } from "../api/Session";
import { Setting, settingsAPI } from "../api/Settings";
import { noop } from "./SessionProvider";

interface SettingsContext {
  set(s: Setting): void;
  hasSettings: boolean;
  isLoading: boolean;
  settings: Setting[] | null | typeof LOADING;
}

const settingsContext = createContext<SettingsContext>({
  set: noop,
  hasSettings: false,
  isLoading: false,
  settings: null,
});

interface SettingsProviderProps extends PropsWithChildren {}

export const SettingsProvider = React.memo<SettingsProviderProps>(
  (props: SettingsProviderProps) => {
    const [settings, setSettings] = useState<Setting[] | null | typeof LOADING>(
      LOADING
    );
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const handleSettingsChange = (
        settings: Setting[] | typeof LOADING | null
      ) => {
        if (settings === LOADING) {
          setLoading(true);
          setSettings(null);
        } else {
          setSettings(settings);
          setLoading(false);
        }
      };
      const unsub = settingsAPI.subscribe(handleSettingsChange);
      return unsub;
    }, []);

    const set = useCallback(
      (setting: Setting) => {
        if (settings && settings !== LOADING) {
          settingsAPI.updateSetting(setting);
        }
      },
      [settings]
    );

    const value = useMemo(() => {
      return {
        set,
        hasSettings: !!settings,
        isLoading: loading,
        settings: settings,
      };
    }, [settings, set, loading]);

    return (
      <settingsContext.Provider value={value}>
        {props.children}{" "}
      </settingsContext.Provider>
    );
  }
);

export const useSettings = () => {
  return useContext(settingsContext);
};
