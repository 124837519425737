import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  BasicCredentials,
  EmailCredentials,
  LOADING,
  Session,
  SessionApi,
} from "../api/Session";

export const noop = (...args: any) => {};

interface SessionContext {
  loginEmail(emailCredentials: EmailCredentials): void;
  loginBasic(basicCredentials: BasicCredentials): void;
  logout(): void;
  hasSession: boolean;
  session: Session | typeof LOADING | null;
  isLoading: boolean;
}

const sessionContext = createContext<SessionContext>({
  loginEmail: noop,
  loginBasic: noop,
  logout: noop,
  hasSession: false,
  session: null,
  isLoading: false,
});

interface SessionProviderProps extends PropsWithChildren {}

export const sessionApi = new SessionApi();

export const SessionProvider = React.memo<SessionProviderProps>(
  (props: SessionProviderProps) => {
    const [session, setSession] = useState(sessionApi.getSession());
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const handleSessionChange = (
        session: Session | typeof LOADING | null
      ) => {
        if (session === LOADING) {
          setLoading(true);
          setSession(null);
        } else {
          setSession(session);
          setLoading(false);
        }
      };
      const unsub = sessionApi.subscribe(handleSessionChange);
      return unsub;
    }, []);

    const value = useMemo(() => {
      return {
        loginEmail: sessionApi.loginEmail,
        loginBasic: sessionApi.loginBasic,
        logout: sessionApi.logout,
        hasSession: !!session,
        isLoading: loading,
        session: session,
      };
    }, [session, loading]);

    return (
      <sessionContext.Provider value={value}>
        {props.children}{" "}
      </sessionContext.Provider>
    );
  }
);

export const useSession = () => {
  return useContext(sessionContext);
};
