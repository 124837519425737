import { FieldError, useSimpleForm } from "../../../hooks/useSimpleForm";

const onlyWhitespaceExp = /^\s*$/;

export const useVocodaForm = () => {
  return useSimpleForm({
    fields: {
      text_to_speak: {
        name: "text_to_speak",
        display_name: "Zeggen",
        validator(val) {
          if (!val) {
            return [{ code: "FIELD_ERRROR_REQUIRED" }];
          }
          if (onlyWhitespaceExp.test(val as string)) {
            return [{ code: "FIELD_ERRROR_REQUIRED" }];
          }
        },
        initialValue: "",
      },
      sentiment: {
        name: "sentiment",
        display_name: "Emotie",
        validator(val) {
          return !val ? [{ code: "FIELD_ERRROR_REQUIRED" }] : undefined;
        },
        initialValue: "neutral",
      },
      temperature: {
        name: "temperature",
        display_name: "Expressie",
        validator(val: number, field) {
          const errors = [
            val > field.options.max && { code: "FIELD_ERRROR_MAX" },
            val < field.options.min && { code: "FIELD_ERRROR_MIN" },
          ].filter(Boolean) as FieldError[];

          return errors.length > 0 ? errors : undefined;
        },
        initialValue: 0,
        options: {
          max: 5,
          min: -5,
        },
      },
    },
  });
};
