import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Sentence, VocodaApi } from "../api/VocodaApi";
import { useSession } from "./SessionProvider";
import { LOADING } from "../api/Session";

interface VocodaContext {
  speak:
    | null
    | ((sentence: Sentence, forceRequest?: boolean) => Promise<string>);
  flag:
    | null
    | ((sentence: Sentence, forceRequest?: boolean) => Promise<string>);
}

const vocodaContext = createContext<VocodaContext>({
  speak: null,
  flag: null,
});

interface VocodaProviderProps extends PropsWithChildren {}

export const VocodaProvider = (props: VocodaProviderProps) => {
  const session = useSession();

  const [vocodaApi, setVocodaApi] = useState(() =>
    session.session && session.session !== LOADING
      ? new VocodaApi(session.session.token)
      : null
  );

  useEffect(() => {
    setVocodaApi(
      session.session && session.session !== LOADING
        ? new VocodaApi(session.session.token)
        : null
    );
  }, [session.session]);

  const value = useMemo(() => {
    return {
      speak: vocodaApi?.speak || null,
      flag: vocodaApi?.flag || null,
    };
  }, [vocodaApi]);

  return (
    <vocodaContext.Provider value={value}>
      {props.children}{" "}
    </vocodaContext.Provider>
  );
};

export const useVocoda = () => {
  return useContext(vocodaContext);
};
