const makeHashColor = (hash: string) => {
  const [be = "unknown", fe = "unknown"] = hash.split("-");
  const h = be.slice(1, 3) + fe.slice(1, 3); // six chars
  const n = parseInt(h, 16) || 0; // parse as hex (for non hash values)
  const s = `000000${n.toString(16)}`; // pad with zeroes render back to hex
  const v = s.slice(-6);
  const c = `#${v}`; //
  const d =
    parseInt(v.slice(0, 2), 16) +
      parseInt(v.slice(2, 4), 16) +
      parseInt(v.slice(4, 6), 16) <
    255 * 3 * 0.5;
  return { color: c, isDark: d, hash, short: makeShort(hash) };
};

const makeShort = (hash: string) => {
  const [be = "unknown", fe = "unknown"] = hash.split("-");
  return `${be.slice(0, 5)}-${fe.slice(0, 5)}`;
};

export const releaseHash = makeHashColor(
  process.env.REACT_APP_RELEASE_VERSION || "unknown-unknown"
);

export const logVersion = () => {
  console.info(
    `%cApp version: %c${releaseHash.hash || "unknown"}`,
    "line-height: 36px; background-color: #282828; color: #f9d646 ; padding: 5px; border-radius:  999px 0px 0px 999px; border: solid 2px #282828",
    `line-height: 36px; font-weight: 900; background-color: ${
      releaseHash.isDark ? "#e8e8e8" : "#282828"
    }; color: ${
      releaseHash.color
    }; padding: 5px; border-radius: 0px 999px 999px 0px; border: solid 2px #282828;
      } `
  );
};
