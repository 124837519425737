import { Divider, Grid } from "@mui/material";
import { Sentence, Sentiment } from "../../../api/VocodaApi";

import { KeyboardEventHandler, useCallback, useEffect, useState } from "react";
import { useVocodaForm } from "../hooks/useVocodaForm";
// import { SentimentBox } from "./SentimentBox";
// import { SliderBox } from "./SliderBox";
import { TextBox } from "./TextBox";
// import { TemperatureBox } from "./TemperatureBox";
import { ShortcutsPanel } from "./ShortcutsPanel";
import { EmotionPicker } from "./EmotionPicker";

interface Props {
  speakSentence(s: Sentence, forceRequest?: boolean): void;
  showDial: boolean;
}

export const SpeakForm = (props: Props) => {
  const { speakSentence, showDial } = props;
  const { valid, values, setValues, errors } = useVocodaForm();

  const [keyboardShow, setKeyboardShow] = useState(false);
  useEffect(() => {
    if ("visualViewport" in window) {
      const VIEWPORT_VS_CLIENT_HEIGHT_RATIO = 0.75;

      const handleResize = (event: Event) => {
        if (
          ((event.target as VisualViewport).height *
            (event.target as VisualViewport).scale) /
            window.screen.height <
          VIEWPORT_VS_CLIENT_HEIGHT_RATIO
        ) {
          console.log("keyboard is shown");
          setKeyboardShow(true);
        } else {
          console.log("keyboard is hidden");
          setKeyboardShow(false);
        }
      };

      window.visualViewport?.addEventListener("resize", handleResize);
      return () =>
        window.visualViewport?.removeEventListener("resize", handleResize);
    }
  });

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!valid) {
        return;
      }

      const { text_to_speak, sentiment, temperature } = values;

      speakSentence({
        words: text_to_speak as string,
        sentiment: sentiment as Sentiment,
        temperature: temperature as number,
      });
      setValues((values) => ({ ...values, text_to_speak: "" }));
    },
    [speakSentence, values, setValues, valid]
  );

  const handleButtonSubmit = useCallback(
    (ev?: React.MouseEvent) => {
      const { text_to_speak, sentiment, temperature } = values;

      speakSentence(
        {
          words: text_to_speak as string,
          sentiment: sentiment as Sentiment,
          temperature: temperature as number,
        },
        !ev
      );
      setValues((values) => ({ ...values, text_to_speak: "" }));
    },
    [speakSentence, values, setValues]
  );

  const handleKeyboardSubmit: KeyboardEventHandler<HTMLFormElement> =
    useCallback((ev) => {
      if (ev.key === "Enter" && ev.ctrlKey) {
        (ev.target as HTMLTextAreaElement).form?.requestSubmit();
      }
    }, []);

  const handleShortcutClick = useCallback(
    (text_to_speak: string) => {
      const { sentiment, temperature } = values;
      speakSentence({
        words: text_to_speak as string,
        sentiment: sentiment as Sentiment,
        temperature: temperature as number,
      });
    },
    [speakSentence, values]
  );

  const [shortcutsOpen, setShortcutsOpen] = useState(false);
  const handleShortcutsToggle = useCallback(
    (ev: React.MouseEvent) => {
      ev.stopPropagation();
      ev.preventDefault();
      setShortcutsOpen((o) => !o);
    },
    [setShortcutsOpen]
  );

  return (
    <form onSubmit={handleSubmit} onKeyDown={handleKeyboardSubmit}>
      <Grid container spacing={2}>
        {showDial && (
          <Grid item xs={12}>
            <EmotionPicker values={values} setValues={setValues} />
          </Grid>
        )}
        {!showDial && <Grid item xs={12} />}
        {!keyboardShow && (
          <>
            <Grid item xs={12}>
              <ShortcutsPanel
                onShortcutClick={handleShortcutClick}
                open={shortcutsOpen}
                onToggleClick={handleShortcutsToggle}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        )}

        {/* <Grid item xs={7}>
          <SentimentBox values={values} setValues={setValues} />
        </Grid>
        <Grid item xs={5}>
          <TemperatureBox values={values} setValues={setValues} />
        </Grid>
        <Grid item xs={12}>
          <SliderBox values={values} setValues={setValues} />
        </Grid> */}
        <Grid item xs={12}>
          {/* <FormHelperText error={!!errors}>
          {errors ? JSON.stringify(errors, null, 2) : " "}
        </FormHelperText> */}
          <TextBox
            values={values}
            setValues={setValues}
            errors={errors}
            valid={valid}
            onButtonClick={handleButtonSubmit}
            onButtonLongPress={handleButtonSubmit}
          />
        </Grid>
      </Grid>
    </form>
  );
};
