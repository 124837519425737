import { Setting } from "../../api/Settings";
import {
  Checkbox,
  FormLabel,
  Select,
  InputLabel,
  SelectChangeEvent,
  FormControl,
} from "@mui/material";

interface Props {
  setting: Setting;

  set(s: Setting): void;
}
export const EditableSetting = (props: Props) => {
  const { setting, set } = props;

  switch (setting.key) {
    case "model":
      return (
        <FormControl fullWidth={true}>
          <InputLabel id={`${setting.key}-label`}>Spraak-model</InputLabel>
          <Select
            fullWidth={true}
            native={true}
            variant="filled"
            placeholder="Spraak-model"
            value={setting.value as string}
            onChange={(ev: SelectChangeEvent) =>
              set({ ...setting, value: ev.target.value })
            }
            labelId={`${setting.key}-label`}
          >
            <option value="styletts">Snel (stts)</option>
            <option value="vocoda">Accuraat (vcda)</option>
          </Select>
        </FormControl>
      );

    case "speaker":
      return (
        <FormControl fullWidth={true}>
          <InputLabel id={`${setting.key}-label`}>Stem</InputLabel>
          <Select
            fullWidth={true}
            native={true}
            variant="filled"
            placeholder="Stem"
            value={setting.value as string}
            onChange={(ev: SelectChangeEvent) =>
              set({ ...setting, value: ev.target.value })
            }
            labelId={`${setting.key}-label`}
          >
            <option value="default">Default</option>
            <option value="dolly_nl">Dolly</option>
            {/* <option value="dolly-fleur_nl">Dolly+Fleur</option> */}
          </Select>
        </FormControl>
      );
    case "show_dial":
      return (
        <FormControl fullWidth={true}>
          <FormLabel
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Toon sentiment-keuze
            <Checkbox
              checked={!!setting.value}
              onChange={() => {
                set({ ...setting, value: !setting.value });
              }}
            />
          </FormLabel>
        </FormControl>
      );

    case "short_sentence_augment":
      return (
        <FormControl fullWidth={true}>
          <FormLabel
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Korte zinnen fix
            <Checkbox
              checked={!!setting.value}
              onChange={() => {
                set({ ...setting, value: !setting.value });
              }}
            />
          </FormLabel>
        </FormControl>
      );
    default:
      return null;
  }
};
