import { styled } from "@mui/material";

export const T = styled("span")(({ theme }) => ({
  fontFamily: "Inter",
  fontSize: 16,
  fontWeight: 400,
  lineHeight: "19px",
  letterSpacing: "0em",
  textAlign: "left",
}));
