import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";

import "@fontsource/inter";
import * as Sentry from "@sentry/react";
import { logVersion } from "./views/Vocoda/utils/appVersion";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://0e613b2d7b106600fcd27ae7b6e4da18@o54987.ingest.us.sentry.io/4507453460054016",
    sampleRate: 1.0,
    tracesSampleRate: 1.0,
    integrations: function (integrations) {
      // integrations will be all default integrations
      return integrations.filter(function (integration) {
        return integration.name !== "Dedupe";
      });
    },
  });
}

logVersion();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
