import localforage from "localforage";
import { Callback, Subscription } from "./Subscription";
import { Sentence } from "./VocodaApi";

export class SpeechHistory {
  private store: LocalForage;
  private pubsub = new Subscription<Sentence[]>();
  private sentences: Sentence[] = [];

  constructor() {
    this.store = localforage.createInstance({
      name: "speech-history",
    });
    this.getSentences();
  }

  getSentences = async () => {
    const keys = await this.store.keys();
    this.sentences = (await Promise.all(
      keys.map((k) => this.store.getItem(k))
    )) as Sentence[];

    this.sentences.sort((a, b) => (b.created_at || 0) - (a.created_at || 0));
    this.pubsub.publish(this.sentences);
    return this.sentences;
  };

  addSentence = async (s: Sentence) => {
    var now = Date.now();

    await this.store.setItem(this.makeId(s, now), {
      ...s,
      created_at: now,
    });
    this.getSentences();
  };

  updateSentence = async (s: Sentence) => {
    await this.store.setItem(this.makeId(s, s.created_at as number), s);
    this.getSentences();
  };

  getRepeated = async (s: Sentence) => {
    const keys = await this.store.keys();
    const sentences = (await Promise.all(
      keys.map((k) => this.store.getItem(k))
    )) as Sentence[];
    sentences.sort((a, b) => (b.created_at || 0) - (a.created_at || 0));
    let idx = sentences.findIndex(
      (s2) =>
        this.makeId(s2, s2.created_at as number) ===
        this.makeId(s, s.created_at as number)
    );
    const repeats = [];

    while (sentences[idx]?.words === s.words) {
      repeats.push(sentences[idx]);
      idx -= 1;
    }
    return Promise.resolve(repeats);
  };

  removeSentence = async (s: Sentence, repeated = true) => {
    if (repeated) {
      const reps = await this.getRepeated(s);
      await Promise.all(
        reps.map((r) => {
          return this.store.removeItem(this.makeId(r, r.created_at as number));
        })
      );
    } else {
      await this.store.removeItem(this.makeId(s, s.created_at as number));
    }
    this.getSentences();
  };

  clear = async () => {
    await this.store.clear();
    this.getSentences();
  };

  makeId = (s: Sentence, now: number) => now + s.words;

  subscribe = (cb: Callback<Sentence[]>) => {
    this.pubsub.subscribe(cb);
    cb(this.sentences);
  };
}
