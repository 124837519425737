import { useCallback, useMemo } from "react";
import { Sentence } from "../../../api/VocodaApi";
import { RecentlyUsedItem } from "./ui/RecentlyUsedItem";
import { RoundButton } from "./ui/RoundButton";
import { T } from "./ui/T";
import { Stack } from "@mui/material";

interface Props {
  sentences: Sentence[];
  speakSentence(s: Sentence, forceRequest?: boolean): void;
  onAddAsShortcut(s: Sentence): void;
  onFlagSentence(s: Sentence): void;
  onRemoveSentence(s: Sentence): void;
  onClearAll(): void;
  error?: Error;
}

interface RepeatableSentence extends Sentence {}

export const RecentlyUsed = (props: Props) => {
  const {
    sentences,
    speakSentence,
    onAddAsShortcut,
    onRemoveSentence,
    onFlagSentence,
    onClearAll,
    error,
  } = props;

  const handleSwipeRight = useCallback(
    (s: Sentence) => {
      onAddAsShortcut(s);
    },
    [onAddAsShortcut]
  );

  const handleSwipeLeft = useCallback(
    (s: Sentence) => {
      onFlagSentence(s);
    },
    [onFlagSentence]
  );

  const handleLongPress = useCallback(
    (s: Sentence) => {
      onRemoveSentence(s);
    },
    [onRemoveSentence]
  );

  const recent = useMemo(() => {
    return [...sentences]
      .reverse()
      .reduce((acc, val, idx, arr) => {
        if (arr[idx - 1]?.words === val.words) {
          acc[acc.length - 1] = {
            ...acc[acc.length - 1],
            repeat: (acc[acc.length - 1].repeat || 0) + 1,
          };
        } else {
          acc = [...acc, val];
        }
        return acc;
      }, [] as RepeatableSentence[])
      .reverse()
      .slice(0, 50);
  }, [sentences]);

  return (
    <>
      {recent.map(({ repeat, ...s }, idx, arr) => {
        return (
          <RecentlyUsedItem
            key={s.created_at + s.words}
            sentence={s}
            onSwipeRight={handleSwipeRight}
            onSwipeLeft={handleSwipeLeft}
            onLongPress={handleLongPress}
            onSpeakSentence={speakSentence}
            repeated={repeat}
            error={idx === 0 ? error : undefined}
          />
        );
      })}
      {recent.length < 10 && (
        <Stack>
          <T
            sx={{
              p: 2,
              color: "#658",
              fontSize: "1.2em",
              lineHeight: 1.6,
            }}
          >
            Welkom in de Vocoda Assistive app.
          </T>
          <T
            sx={{
              p: 2,
              color: "#658",
              fontSize: "1.2em",
              lineHeight: 1.6,
            }}
          >
            <span
              style={{ background: "white", padding: 4, borderRadius: 999 }}
            >
              💬
            </span>{" "}
            Gebruikt het tekstveld onderaan om zinnen door de app uit te laten
            spreken. Het werkt net als de meest chat apps (zoals iMessage en
            WhatsApp).
          </T>

          <T
            sx={{
              p: 2,
              color: "#658",
              fontSize: "1.2em",
              lineHeight: 1.6,
            }}
          >
            <span
              style={{ background: "white", padding: 4, borderRadius: 999 }}
            >
              ↻
            </span>{" "}
            Om zinnen opnieuw uit te spreken, druk je op het herhaal symbool.
            Lang ingedrukt houden, laat de zin opnieuw gegenereerd worden.
          </T>

          <T
            sx={{
              p: 2,
              color: "#658",
              fontSize: "1.2em",
              lineHeight: 1.6,
            }}
          >
            <span
              style={{ background: "white", padding: 4, borderRadius: 999 }}
            >
              👆⏱️
            </span>{" "}
            Om zinnen uit je geschiedenis te verwijderen, houd je ze lang
            ingedrukt.
          </T>
          <T
            sx={{
              p: 2,
              color: "#658",
              fontSize: "1.2em",
              lineHeight: 1.6,
            }}
          >
            <span
              style={{ background: "white", padding: 4, borderRadius: 999 }}
            >
              ⇐👆
            </span>{" "}
            Om zinnen als foutief te markeren, kun je ze naar links swipen.
          </T>
          <T
            sx={{
              p: 2,
              color: "#658",
              fontSize: "1.2em",
              lineHeight: 1.6,
            }}
          >
            <span
              style={{ background: "white", padding: 4, borderRadius: 999 }}
            >
              👆⇒
            </span>{" "}
            Om zinnen toe te voegen aan je favorieten, kun je ze naar rechts
            swipen.
          </T>
          <T
            sx={{
              p: 2,
              color: "#658",
              fontSize: "1.2em",
              lineHeight: 1.6,
            }}
          >
            <span
              style={{ background: "white", padding: 4, borderRadius: 999 }}
            >
              👆⏱️
            </span>{" "}
            Om zinnen uit je favorieten te verwijderen, houd je ze lang
            ingedrukt.
          </T>

          <T
            sx={{
              p: 2,
              color: "#658",
              fontSize: "1.2em",
              lineHeight: 1.6,
            }}
          >
            Je zinnen verschijnen hier...
          </T>
        </Stack>
      )}

      {sentences.length > 0 && (
        <RoundButton
          onClick={onClearAll}
          color="secondary"
          style={{ position: "absolute", top: 50, right: 20 }}
        >
          Wis alles
        </RoundButton>
      )}
    </>
  );
};
