import { useMemo, useState } from "react";

export interface FieldError {
  code: string;
  detail?: string;
  source?: string;
}

type Errors = FieldError[];
export type AllErrors = Record<string, Errors>;

interface Field<V = unknown> {
  name: string;
  display_name?: string;
  validator(value: V, field: Field<V>, values: Values): void | Errors;
  initialValue?: V;
  options?: any;
}

interface FormConfig {
  fields: { [key: string]: Field };
}

export interface Values {
  [key: string]: unknown;
}

export const useSimpleForm = (config: FormConfig) => {
  const [values, setValues] = useState(() => {
    return Object.keys(config.fields).reduce((acc, field) => {
      acc[field] = config.fields[field].initialValue;
      return acc;
    }, {} as Values);
  });

  const errors = useMemo(() => {
    return Object.keys(config.fields).reduce((acc, field) => {
      const err = config.fields[field].validator(
        values[field],
        config.fields[field],
        values
      );
      if (err) {
        return acc ? { ...acc, field: err } : { field: err };
      }
      return acc;
    }, undefined as undefined | AllErrors);
  }, [values, config.fields]);

  return { valid: !errors, values, setValues, errors, fields: config.fields };
};
