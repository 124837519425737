import RepeatIcon from "@mui/icons-material/Refresh";
import { Box, Slide, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Sentence } from "../../../../api/VocodaApi";
import { Emoji } from "./Emoji";
import { RoundButton } from "./RoundButton";
import { LongPressChip } from "./Shortcut";

interface Props {
  sentence: Sentence;
  repeated?: number;
  onSpeakSentence(s: Sentence, forceFetch?: boolean): void;
  onSwipeRight?(s: Sentence): void;
  onSwipeLeft?(s: Sentence): void;
  onLongPress?(s: Sentence): void;
  error?: Error;
}

export const RecentlyUsedItem = (props: Props) => {
  const {
    sentence: s,
    onSpeakSentence,
    onSwipeRight,
    onSwipeLeft,
    onLongPress,
    repeated,
    error,
  } = props;

  const [itemRef, setItemRef] = useState<HTMLDivElement | null>(null);

  const handleClick = useCallback(() => {
    onSpeakSentence({ ...s });
  }, [onSpeakSentence, s]);

  const handleLongPress = useCallback(() => {
    onSpeakSentence(s, true);
  }, [onSpeakSentence, s]);

  useEffect(() => {
    if (itemRef) {
      let startX: number | undefined;
      let startT: NodeJS.Timeout | number | undefined;
      const el = itemRef as HTMLDivElement;
      let newX = 0;

      const handleMove = (ev: TouchEvent | MouseEvent) => {
        newX =
          (ev.type === "touchmove"
            ? (ev as TouchEvent).touches[0].clientX
            : (ev as MouseEvent).clientX) - (startX as number);
        if (!onSwipeLeft) {
          newX = Math.max(0, newX);
        }

        if (!onSwipeRight) {
          newX = Math.min(0, newX);
        }

        if (Math.abs(newX) > 10 && startT) {
          delete itemRef.dataset.longPress;
          clearTimeout(startT);
          startT = undefined;
        }

        el.style.transition = "";
        el.style.transform = `translateX(${newX}px)`;

        el.style.background =
          newX > 100 && onSwipeRight
            ? `rgb(140, 220, 170 )`
            : newX < -80 && onSwipeLeft
            ? `rgb(230, 150, 120 )`
            : "";
      };

      const handleUp = (ev: TouchEvent | MouseEvent) => {
        startX = undefined;
        window.removeEventListener("touchmove", handleMove);
        window.removeEventListener("mousemove", handleMove);
        window.removeEventListener("touchend", handleUp);
        window.removeEventListener("mouseup", handleUp);

        if (startT) {
          delete itemRef.dataset.longPress;
          clearTimeout(startT);
          startT = undefined;
        }

        el.style.transition = "transform 0.150s ease-out";

        el.style.transform = "";
        el.style.background = "";

        if (newX < -100) {
          onSwipeLeft?.(s);
        } else if (newX > 100) {
          onSwipeRight?.(s);
        }
      };

      const handleSwipeStart = (ev: TouchEvent | MouseEvent) => {
        startX =
          ev.type === "touchstart"
            ? (ev as TouchEvent).touches[0].clientX
            : (ev as MouseEvent).clientX;
        // const startY = ev.clientY

        if (!itemRef.querySelector("[data-long-press]")) {
          startT = setTimeout(() => {
            delete itemRef.dataset.longPress;
            onLongPress?.(s);
          }, 1000);
          itemRef.dataset.longPress = "longpress";
        }

        window.addEventListener("touchmove", handleMove);
        window.addEventListener("mousemove", handleMove);
        window.addEventListener("touchend", handleUp);
        window.addEventListener("mouseup", handleUp);
      };

      itemRef.addEventListener("touchstart", handleSwipeStart);
      itemRef.addEventListener("mousedown", handleSwipeStart);

      return () => {
        itemRef.removeEventListener("touchstart", handleSwipeStart);
        itemRef.removeEventListener("mousedown", handleSwipeStart);
        if (startX !== undefined) {
          window.removeEventListener("touchmove", handleMove);
          window.removeEventListener("mousemove", handleMove);
          window.removeEventListener("touchend", handleUp);
          window.removeEventListener("mouseup", handleUp);
        }
      };
    }
  }, [itemRef, onSwipeRight, onSwipeLeft, onLongPress, s]);

  return (
    <Slide
      direction="up"
      appear={true}
      enter={true}
      exit={true}
      in={true}
      mountOnEnter
      unmountOnExit
      key={`${s.words}-${s.sentiment}-${s.temperature}-${s.created_at}`}
    >
      <Box flexShrink={1}>
        <LongPressChip
          style={{ paddingRight: 8 }}
          ref={setItemRef}
          color={s.flagged || error ? "error" : undefined}
        >
          <Stack>
            <Stack
              key={s.words}
              direction={"row"}
              spacing={1}
              // divider={<Divider orientation="vertical" flexItem />}
              alignItems={"center"}
              justifyContent={"flex-start"}
              style={{ position: "relative" }}
            >
              {/* <Typography flexGrow={0} fontSize={28}> */}
              <div
                style={{
                  width: 20,
                  height: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Emoji
                  preset={{
                    name: "test",
                    ...s,
                  }}
                />
              </div>
              {/* </Typography> */}
              <Typography flexGrow={1} color={error ? "red" : undefined}>
                {s.words}
              </Typography>

              <RoundButton
                color="ghost"
                style={{ alignSelf: "end" }}
                type="button"
                onClick={handleClick}
                onLongPress={handleLongPress}
              >
                <RepeatIcon />
              </RoundButton>
              {!!repeated && (
                <Typography color="GrayText" variant="overline">
                  {repeated + 1}×
                </Typography>
              )}
            </Stack>
            {error && (
              <Typography color="red" variant="overline">
                {typeof error === "string" ? error : error.message}×
              </Typography>
            )}
          </Stack>
        </LongPressChip>
      </Box>
    </Slide>
  );
};
