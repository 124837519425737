import { useEffect, useState } from "react";

export const useLongPress = (
  handleLongPress?: () => void,
  handleClick?: (ev?: MouseEvent | TouchEvent) => void
) => {
  const [ref, setRef] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (ref && handleLongPress) {
      let startX: number | undefined;
      let startY: number | undefined;
      let newX: number;
      let newY: number;

      let t: NodeJS.Timeout | number | null;

      const handleEnd = (ev: TouchEvent | MouseEvent) => {
        ev.preventDefault();
        // ev.stopPropagation();
        delete ref.dataset.longPress;

        if (t) {
          clearTimeout(t);

          handleClick?.(ev);
        }
        ref.removeEventListener("mouseup", handleEnd);
        ref.removeEventListener("touchend", handleEnd);
      };

      const handleMove = (ev: MouseEvent | TouchEvent) => {
        newX =
          (ev.type === "touchmove"
            ? (ev as TouchEvent).touches[0].clientX
            : (ev as MouseEvent).clientX) - (startX as number);
        newY =
          (ev.type === "touchmove"
            ? (ev as TouchEvent).touches[0].clientY
            : (ev as MouseEvent).clientY) - (startY as number);

        const dist = Math.max(Math.abs(newX), Math.abs(newY));
        if (dist > 10) {
          delete ref.dataset.longPress;

          if (t) {
            clearTimeout(t);
            t = null;
          }
        }
      };

      const handleStart = (ev: MouseEvent | TouchEvent) => {
        ref.dataset.longPress = "longpress";
        startX =
          ev.type === "touchstart"
            ? (ev as TouchEvent).touches[0].clientX
            : (ev as MouseEvent).clientX;
        startY =
          ev.type === "touchstart"
            ? (ev as TouchEvent).touches[0].clientY
            : (ev as MouseEvent).clientY;
        // ev.preventDefault();
        // ev.stopPropagation();

        ref.addEventListener("mousemove", handleMove);
        ref.addEventListener("touchmove", handleMove);

        ref.addEventListener("mouseup", handleEnd);
        ref.addEventListener("touchend", handleEnd);

        if (t) {
          clearTimeout(t);
        }
        t = setTimeout(() => {
          delete ref.dataset.longPress;

          ref.removeEventListener("mouseup", handleEnd);
          ref.removeEventListener("touchend", handleEnd);
          ref.removeEventListener("mousemove", handleMove);
          ref.removeEventListener("touchmove", handleMove);

          handleLongPress?.();
        }, 1000);
      };

      ref.addEventListener("mousedown", handleStart);
      ref.addEventListener("touchstart", handleStart);

      return () => {
        if (t) {
          delete ref.dataset.longPress;

          clearTimeout(t);
          ref.removeEventListener("mouseup", handleEnd);
          ref.removeEventListener("touchend", handleEnd);
          ref.removeEventListener("mousemove", handleMove);
          ref.removeEventListener("touchmove", handleMove);
        }
        ref.removeEventListener("mousedown", handleStart);
        ref.removeEventListener("touchstart", handleStart);
      };
    }
  }, [ref, handleLongPress, handleClick]);

  return setRef;
};
