import { CircularProgress } from "@mui/material";
import { SignIn } from "../views/SignIn";
import { Vocoda } from "../views/Vocoda";
import { useSession } from "./SessionProvider";
import { VocodaProvider } from "./VocodaProvider";

export const Routes = () => {
  const { hasSession, isLoading } = useSession();

  if (isLoading) {
    return <CircularProgress />;
  }
  if (!hasSession) {
    return <SignIn />;
  }
  if (hasSession) {
    return (
      <VocodaProvider>
        <Vocoda />
      </VocodaProvider>
    );
  }

  return <SignIn />;
};
